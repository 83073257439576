import { useEffect, useMemo, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { useDispatch } from "react-redux";

import UploadFile from "./UploadFile";
import UploadBlack from "../../../assets/upload-black.png";
import UploadWhite from "../../../assets/upload-white.png";
import QuestionMarkBlack from "../../../assets/question-black.png";
import QuestionMarkWhite from "../../../assets/question-white.png";
import Document from "../../../assets/myDocument.png";
import DocumentWhite from "../../../assets/Document-white.png";
import Card from "../../../assets/Card-Payment.png";
import CardWhite from "../../../assets/Card-Payment-white.png";
import Invoice from "./Invoice";
import ClearanceRequest from "./ClearanceRequest";
import {
  completeEDocumentation,
  getAllDocumentHistory,
  getClearanceRequests,
  getDocumentTypes,
  getEdocumentationFee,
} from "../../../redux/action/e-documentation";
import { useSelector } from "react-redux";
import ImageModal from "./ImageModal";
import DocumentsHistory from "./DocumentsHistory";
import PaymentHistory from "./PaymentHistory";
import ListOfUploads from "./ListOfUploads";
import { LoadingItem } from "../../../shared/components/loading";
import { Spinner } from "react-bootstrap";
import { AlertMsg } from "../../../shared/components/alert-msg";

export const formatDate = (dateString: string) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${day}/${month}/${year}`;
};

const Tabs = ({ studentInfo }: any) => {
  const [activeTab, setActiveTab] = useState("upload");
  const [show, setShow] = useState<boolean>(false);
  const [docs, setDocs] = useState<any[]>([]);
  const [thumbnail, setThumbnail] = useState<any>({ Front: "", Back: "" });
  const [activeSide, setActiveSide] = useState("");
  const [documentType, setDocumentType] = useState<string>("");
  const [uploadPayload, setUploadPayload] = useState<any>({
    DocumentType: "",
    ReferenceId: "",
    DateIssued: null,
  });
  const [loadingSubmitReview, setLoadingSubmitReview] =
    useState<boolean>(false);

  const formattedDate = formatDate(uploadPayload.DateIssued);

  const uploadPayloadObj = {
    ...uploadPayload,
    DateIssued: formattedDate,
    StudentUniqueId: studentInfo?.studentUniqueId,
  };
  const dispatch = useDispatch();
  const { paymentStatus, documents, clearanceRequests, documentHistory } =
    useSelector((state: any) => state.eDocumentation);

  const hasUploadedAllDocuments = useMemo(() => {
    const requests = [...documentHistory, ...clearanceRequests];
    return documents?.every((document: string) =>
      requests.some(
        (request: any) =>
          request.documentType.toLowerCase() === document.toLowerCase() &&
          request?.status.toLowerCase() !== "rejected"
      )
    );
  }, [documentHistory, clearanceRequests, documents]);

  const studentInfoValues = {
    studentUniqueId: studentInfo?.studentUniqueId,
    matricNumber: studentInfo?.matricNumber,
    level: studentInfo?.level,
    fullName: studentInfo?.fullName,
    session: studentInfo?.currentSession,
    email: studentInfo?.email,
    phoneNumber: studentInfo?.phoneNumber,
    faculty: studentInfo?.faculty,
    department: studentInfo?.department,
    programme: studentInfo?.programme,
  };
  const clearanceRequestBody = {
    studentId: studentInfo?.studentUniqueId,
    matricNumber: studentInfo?.matricNumber,
    level: studentInfo?.level,
    session: studentInfo?.currentSession,
  };

  useEffect(() => {
    dispatch(getEdocumentationFee(studentInfoValues));
    dispatch(getDocumentTypes(studentInfo?.level, studentInfo?.matricNumber));
    dispatch(getClearanceRequests(clearanceRequestBody));
    dispatch(getAllDocumentHistory(clearanceRequestBody));
    setThumbnail({ Front: "", Back: "" });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCompleteEDoc = async () => {
    setLoadingSubmitReview(true);
    const values = {
      studentId: studentInfo?.studentUniqueId,
      level: studentInfo?.level,
      session: studentInfo?.currentSession,
    };
    await completeEDocumentation(values);
    setLoadingSubmitReview(false);
  };

  return (
    <div className="page-tabs-container">
      {!paymentStatus ? (
        <LoadingItem />
      ) : (
        <>
          <ImageModal
            show={show}
            setShow={setShow}
            setDocs={setDocs}
            document={document}
            docs={docs}
            setDocumentType={setDocumentType}
            documentType={documentType}
          />

          <div className="page-tabs">
            <div
              onClick={() => {
                setActiveTab("upload");
                setThumbnail({ Front: "", Back: "" });
              }}
              className={activeTab === "upload" ? "active tab" : "tab"}
            >
              <p>Upload Document </p>
              <img
                src={activeTab === "upload" ? UploadWhite : UploadBlack}
                alt="upload icon"
              />
            </div>
            <div
              onClick={() => {
                setThumbnail({ Front: "", Back: "" });
                setActiveTab("request");
              }}
              className={activeTab === "request" ? "active tab" : "tab"}
            >
              <p>Clearance Request</p>
              <img
                src={
                  activeTab === "request"
                    ? QuestionMarkWhite
                    : QuestionMarkBlack
                }
                alt="Request icon"
              />
            </div>
            <div
              onClick={() => {
                setThumbnail({ Front: "", Back: "" });
                setActiveTab("document");
              }}
              className={activeTab === "document" ? "active tab" : "tab"}
            >
              <p>My Document</p>
              <img
                src={activeTab === "document" ? DocumentWhite : Document}
                alt="Document icon"
              />
            </div>
            <div
              onClick={() => {
                setThumbnail({ Front: "", Back: "" });
                setActiveTab("history");
              }}
              className={activeTab === "history" ? "active tab" : "tab"}
            >
              <p>Payment History</p>
              <img
                src={activeTab === "history" ? CardWhite : Card}
                alt="Card icon"
              />
            </div>
          </div>

          {paymentStatus &&
            paymentStatus === "PENDING" &&
            activeTab === "upload" && (
              <div className="error-message">
                <AlertMsg
                  type="error"
                  message={
                    "Payment is required to upload documents for this session."
                  }
                />
              </div>
            )}

          {activeTab === "upload" ? (
            <div className="level-session">
              <div className="section">
                <label>SESSION</label>
                <input type="text" value={studentInfo.currentSession} />
              </div>
              <div className="section">
                <label>LEVEL</label>
                <input type="text" value={studentInfo?.level} />
              </div>
            </div>
          ) : null}

          {paymentStatus &&
          paymentStatus === "PENDING" &&
          activeTab === "upload" ? (
            <Invoice studentInfo={studentInfo} />
          ) : paymentStatus &&
            paymentStatus === "PAID" &&
            activeTab === "upload" ? (
            <>
              <ListOfUploads />
              <div className="document-info">
                <select
                  onChange={(e) => {
                    setUploadPayload((prevState: any) => ({
                      ...prevState,
                      DocumentType: e.target.value,
                    }));
                    setThumbnail({ Front: null, Back: null });
                  }}
                >
                  <option disabled selected>
                    Select document
                  </option>
                  {documents &&
                    documents?.length &&
                    documents?.map((document: string, index: number) => (
                      <option value={document} key={index}>
                        {document}
                      </option>
                    ))}
                </select>
                <input
                  type="text"
                  value={uploadPayload.referenceId}
                  onChange={(e) => {
                    setUploadPayload((prevState: any) => ({
                      ...prevState,
                      ReferenceId: e.target.value,
                    }));
                  }}
                  placeholder="Reference ID"
                />
                <ReactDatePicker
                  selected={uploadPayload.DateIssued}
                  onChange={(date: Date | null) => {
                    if (date) {
                      setUploadPayload((prevState: any) => ({
                        ...prevState,
                        DateIssued: date,
                      }));
                    } else {
                      setUploadPayload((prevState: any) => ({
                        ...prevState,
                        DateIssued: null,
                      }));
                    }
                  }}
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  maxDate={new Date()}
                  placeholderText="Date issued"
                />
              </div>
              <div className="upload-section">
                <UploadFile
                  pageSide="Front"
                  disabled={
                    !uploadPayload.DateIssued || !uploadPayload.DocumentType
                  }
                  uploadPayload={uploadPayloadObj}
                  thumbnail={thumbnail}
                  setThumbnail={setThumbnail}
                  activeSide={activeSide}
                  setActiveSide={setActiveSide}
                  clearanceRequestBody={clearanceRequestBody}
                />
                <UploadFile
                  pageSide="Back"
                  thumbnail={thumbnail}
                  setThumbnail={setThumbnail}
                  disabled={
                    !uploadPayload.DateIssued || !uploadPayload.DocumentType
                  }
                  uploadPayload={uploadPayloadObj}
                  activeSide={activeSide}
                  setActiveSide={setActiveSide}
                  clearanceRequestBody={clearanceRequestBody}
                />
              </div>
              <div className="submit-upload">
                <button
                  disabled={!hasUploadedAllDocuments || loadingSubmitReview}
                  onClick={handleCompleteEDoc}
                >
                  SUBMIT FOR REVIEW{" "}
                  {loadingSubmitReview ? (
                    <Spinner animation="border" size="sm" />
                  ) : null}
                </button>
                <p>
                  <strong>NOTE:</strong> The "Submit for Review" button will be
                  enabled once all requested documents are uploaded. Click it to
                  submit your documents for review.
                </p>
              </div>
            </>
          ) : activeTab === "request" ? (
            <ClearanceRequest
              setShow={setShow}
              setDocs={setDocs}
              setDocumentType={setDocumentType}
            />
          ) : activeTab === "document" ? (
            <DocumentsHistory
              setDocs={setDocs}
              setDocumentType={setDocumentType}
              setShow={setShow}
            />
          ) : activeTab === "history" ? (
            <PaymentHistory />
          ) : null}
        </>
      )}
    </div>
  );
};

export default Tabs;
