import { Block, Room } from "./hostelBooking/SiteList";

interface SiteProps {
  site: {
    siteName: string;
    blocks: Block[];
  };
  selectedBlock: string;
  selectedRoom: string;
  handleBlockChange: (siteName: string, blockUniqueId: string) => void;
  onRoomSelect: (siteName: string, roomUniqueId: string) => void;
  handleProceed: (siteName: string) => void;
  inputError: boolean;
}

const Site = ({
  site,
  selectedBlock,
  selectedRoom,
  handleBlockChange,
  onRoomSelect,
  handleProceed,
  inputError,
}: SiteProps) => {
  return (
    <div className="site">
      <p>Site: {site.siteName}</p>

      {/* Block Dropdown */}
      <label>Block:</label>
      <select
        className="rooms-dropdown"
        value={selectedBlock}
        onChange={(e) => handleBlockChange(site.siteName, e.target.value)}
        style={{ marginBottom: "15px" }}
      >
        <option value="">Select block</option>
        {site.blocks.map((block) => (
          <option key={block.blockUniqueId} value={block.blockUniqueId}>
            {block.blockName}
          </option>
        ))}
      </select>

      {/* Room Dropdown (enabled only when a block is selected) */}
      <label>Room:</label>
      <select
        className="rooms-dropdown"
        value={selectedRoom}
        onChange={(e) => onRoomSelect(site.siteName, e.target.value)}
        disabled={!selectedBlock}
      >
        <option value="">Select a room</option>
        {selectedBlock &&
          site.blocks
            .find((block: Block) => block.blockUniqueId === selectedBlock)
            ?.rooms.map((room: Room) => (
              <option key={room.roomUniqueId} value={room.roomUniqueId}>
                {room.roomName}
              </option>
            ))}
      </select>

      {/* Error Message */}
      {inputError && !selectedBlock && !selectedRoom && (
        <p className="input-error">Select block and room.</p>
      )}

      {/* Proceed Button */}
      <button
        className="btn"
        onClick={() => handleProceed(site.siteName)}
        disabled={!selectedBlock || !selectedRoom}
      >
        Proceed
      </button>
    </div>
  );
};

export default Site;
