import { useSelector } from "react-redux";
import ExamRules from "./ExamRules";
import FrontPage from "./FrontPage";
import "./index.scss";

const StudentExamCard = () => {
  const studentDetails = useSelector(
    (state: any) => state.allOnboardingReducers?.uGradLogin
  );
  const examCardState = useSelector(
    (state: any) => state.allUGradPortalReducers.UnderGradPrintExamCard
  );
  const studentCourseInfo = examCardState?.request_data?.response;
  const examCardDetails = studentDetails?.request_data?.response?.profile;
  return (
    <div className="main-container" id="table-to-export">
      <div className="main-wrapper">
        <FrontPage
          examCardDetails={examCardDetails}
          courseDetails={studentCourseInfo}
        />
        <ExamRules />
      </div>
    </div>
  );
};

export default StudentExamCard;
