import { ApiService } from "../../../services/apiService";
import { Dispatch } from "redux";
import { toast } from "react-toastify";
import { routes } from "../../../services/paths";
import { handleRequestErrors } from "../../../shared/utils";
import * as types from "../../action-constants/edocumentation";

const fetchEDocumentationFee = (payload: any) => {
  return {
    payload,
    type: types.GET_EDOCUMENTATION_UNDERGRADUATE,
  };
};

const fetchDocumentTypes = (payload: any) => {
  return {
    payload,
    type: types.GET_EDOCUMENT_TYPES,
  };
};

export const uploadingEDoc = (payload: boolean) => {
  return {
    type: types.UPLOADING_EDOCUMENTATION_DOC,
    payload,
  };
};

export const allClearanceRequest = (payload: any) => {
  return {
    type: types.CLEARANCE_REQUESTS,
    payload,
  };
};

export const eDocumentsHistory = (payload: any) => {
  return {
    type: types.DOCUMENTS_HISTORY,
    payload,
  };
};

export const getEdocumentationFee =
  (values: any) => async (dispatch: Dispatch) => {
    await ApiService.request({
      url: `${routes.GET_EDOCUMENTATION_FEE}`,
      http_method: "POST",
      data: values,
      headers: { "X-Signature": routes.apiKey },
    })
      .then((data: any) => {
        if (data?.data) {
          const {
            compulsoryFeeHistories,
            amount,
            paymentType,
            paymentStatus,
            transactionId,
            downloadUrl,
            session
          } = data.data;
          dispatch(
            fetchEDocumentationFee({
              compulsoryFeeHistories,
              amount,
              paymentType,
              paymentStatus,
              downloadUrl,
              transactionId,
              session
            })
          );
        }
      })
      .catch((err: any) => {
        return err;
      });
  };

export const getDocumentTypes = (level: string, matricNumber:string) => async (dispatch: any) => {
  await ApiService.request({
    url: `${routes.GET_DOCUMENTAT_TYPES}?level=${level}&matricNumber=${matricNumber}`,
    http_method: "GET",
    headers: { "X-Signature": routes.apiKey },
  })
    .then((data: any) => {
      if (data.status === 200) {
        dispatch(fetchDocumentTypes(data.data));
      }
    })
    .catch((err: any) => {
      handleRequestErrors(err);
    });
};

export const uplodEDocumentationDocs =
  (values: any, page: string = "front") =>
  async (dispatch: Dispatch) => {
    dispatch(uploadingEDoc(true));
    try {
      const url =
        page === "Front"
          ? routes.UPLOAD_EDOCUMENTATIONDOCS_FRONT
          : routes.UPLOAD_EDOCUMENTATIONDOCS_BACK;
      const response = await ApiService.request({
        url,
        data: values,
        http_method: "POST",
        headers: { "X-Signature": routes.apiKey },
      });
      return response;
    } catch (err: any) {
      if (
        err?.response &&
        err?.response?.data ===
          "This document has already been approved by the Admin. Please contact the ICT Unit for further assistance."
      ) {
        toast.error(err?.response?.data);
      } else {
        toast.error("An error occured");
      }
      dispatch(uploadingEDoc(false));
      handleRequestErrors(err);
    }
  };

export const getClearanceRequests =
  (values: any) => async (dispatch: Dispatch) => {
    await ApiService.request({
      url: `${routes.CLEARANCE_REQUESTS}`,
      http_method: "POST",
      data: values,
      headers: { "X-Signature": routes.apiKey },
    })
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(allClearanceRequest(data.data));
        }
      })
      .catch((err: any) => {
        handleRequestErrors(err);
      });
  };

export const getAllDocumentHistory =
  (values: any) => async (dispatch: Dispatch) => {
    await ApiService.request({
      url: `${routes.DOCUMENTS_HISTORY}`,
      http_method: "POST",
      data: values,
      headers: { "X-Signature": routes.apiKey },
    })
      .then((data: any) => {
        if (data.status === 200) {
          dispatch(eDocumentsHistory(data.data));
        }
      })
      .catch((err: any) => {
        handleRequestErrors(err);
      });
  };

export const completeEDocumentation = async(values: any) =>{
  await ApiService.request({
    url: `${routes.COMPLETE_EDOCUMENTATION}`,
    http_method: "POST",
    data: values,
    headers: { "X-Signature": routes.apiKey }
  }).then((data: any)=>{
    if(data?.status === 200){
      toast.success('Documents submitted for review')
    }
  }).catch((err: any)=>{
    toast.error('An error occured')
    handleRequestErrors(err)
  })
}