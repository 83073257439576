import React, { useState } from "react";
import { useWindowSize } from "../../../hooks/useWindowSize";
import DashUsrIco from "../../../assets/user.png";
// import { connect } from "react-redux";
// import { uGradPortal } from "../../../redux/action/portal/undergraduate";

const SideBar = ({ student }: any) => {
  const [isMoreShown, setShowMore] = useState<boolean>(false);
  const screenResolution: any = useWindowSize();
  return (
    <div className="dahboard_siderbar">
      <div className="student_img">
        <img src={student?.photoId || DashUsrIco} alt="" />
      </div>
      <div className="top_info">
        <div className="info_wrap">
          <div className="info_title">Name :</div>
          <div className="info_data">{student?.fullName}</div>
        </div>
        <div className="info_wrap">
          <div className="info_title">Matric Number :</div>
          <div className="info_data">{student?.matricNumber}</div>
        </div>
        {screenResolution?.width <= 766 && !isMoreShown && (
          <div className="show_more" onClick={() => setShowMore(true)}>
            Show more ..
          </div>
        )}
      </div>

      {(screenResolution?.width >= 767 || isMoreShown) && (
        <div className="other_info">
          <div className="info_content">
            <div className="info_title">Academic session :</div>
            <div className="info_data">{student?.currentSession}</div>
          </div>
          <div className="info_content">
            <div className="info_title">Course of Study :</div>
            <div className="info_data">{student?.programme}</div>
          </div>
          <div className="info_content">
            <div className="info_title">Current Level:</div>
            <div className="info_data">{student?.level}</div>
          </div>
          <div className="info_content">
            <div className="info_title">Department :</div>
            <div className="info_data">{student?.department}</div>
          </div>
          <div className="info_content">
            <div className="info_title">Faculty :</div>
            <div className="info_data">{student?.faculty}</div>
          </div>
          {/* <div className="info_content">
                        <div className="info_title">Student Status :</div>
                        <div className="info_data">LOREM IPSUM</div>
                    </div> */}
          <div className="info_content">
            <div className="info_title">School Fees Status :</div>
            <div className="info_data">
              {student?.hasPaidSchoolFee ? "Paid" : "Not Paid"}
            </div>
          </div>
          <div className="info_content">
            <div className="info_title">Course Reg. Status :</div>
            <div className="info_data">
              {student?.hasRegisteredForCurrentSession
                ? "Completed"
                : "Not Registered"}
            </div>
          </div>
          {/* <div className="info_content">
                        <div className="info_title">Hostel Status :</div>
                        <div className="info_data">{dashboardRequestData?.hasHostelAccommodation ? "Hostel assigned" : "Not assigned"}</div>
                    </div> */}
          {screenResolution?.width <= 766 && isMoreShown && (
            <div className="show_more" onClick={() => setShowMore(false)}>
              Hide details ..
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SideBar;
