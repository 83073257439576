import { connect } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { uGradAdmissions } from "../../../../../redux/action/admissions/undergraduate";
import { appConstantsUGradAdmisssions } from "../../../../../redux/action-constants/admissions/undergraduate";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import { UndergraduateAdmissionStatusChecker } from "../../../../../shared/templates/admissions/undergraduate/index";

import { AlertMsg } from "../../../../../shared/components/alert-msg";
import { LoadingItem } from "../../../../../shared/components/loading";
import { allStatus } from "../../admissionStatusMapping";
const getAdmissionStatus = async ({ pageProps, payload }: any) => {
  await pageProps.getMyAdmissionStatus(payload, true);
};

const getCurrentSession = async ({ pageProps, payload }: any) => {
  await pageProps.getCurrentSession(payload, true);
};

const getIssueActions = (statusMessage: string[]) => {
  let actionData: any = statusMessage.map((eachStatus: string) => {
    // let checkStatus = allStatus.filter((eachItem: any) => eachItem?.status.toLowerCase().includes(eachStatus.toLowerCase()))[0]
    let checkStatus = allStatus.filter(
      (eachItem: any) =>
        eachItem?.status.toLowerCase() == eachStatus.toLowerCase()
    )[0];
    // let checkStatus = allStatus.filter((eachItem: any) => eachItem?.status.toLowerCase() == eachStatus.toLowerCase())[0]

    if (checkStatus) {
      return checkStatus;
    } else {
      checkStatus = allStatus.filter((eachItem: any) =>
        eachStatus.toLowerCase().includes(eachItem?.status.toLowerCase())
      )[0];

      return checkStatus;
    }

    return null;
  });

  return actionData;
};

const ResultReport = ({ statusData }: any) => {
  const screenResolution: any = useWindowSize();

  let actionItems: any[] = getIssueActions(statusData?.problems);

  let fullData = {};
  if (actionItems[0]?.shouldHide) {
    let { problems, ...statusInfo } = statusData;
    fullData = {
      ...actionItems[0],
      ...statusInfo,
    };
  }

  return (
    <>
      {!actionItems[0]?.shouldHide && (
        <div className="report_wrap">
          <div className="report_heading">
            <div className="school">
              IBRAHIM BADAMASI BABANGIDA UNIVERSITY, LAPAI
            </div>
            <div className="p_utme_head">
              2024/2025 POST UTME SCREENING AND RESULT VERIFICATION
            </div>
            <div className="sub_head">RESULT VERIFICATION STATUS REPORT</div>
          </div>
          <div className="report_summary">
            <div className="each_item">
              <div className="item_heading">UTME/DE NUMBER</div>
              <div className="item_data">{statusData?.registrationNumber} </div>
            </div>
            <div className="each_item">
              <div className="item_heading">CANDIDATE NAME</div>
              <div className="item_data">{statusData?.fullName} </div>
            </div>
            <div className="each_item">
              <div className="item_heading">CANDIDATE AGE</div>
              <div className="item_data">{statusData?.age} </div>
            </div>
            <div className="each_item">
              <div className="item_heading">CANDIDATE AGE REQUIREMENT</div>
              <div className="item_data">
                {statusData?.ageRequirementStatus}{" "}
              </div>
            </div>
            <div className="each_item">
              <div className="item_heading">CAPS O'LEVEL STATUS</div>
              <div className="item_data">{statusData?.capStatus} </div>
            </div>
            <div className="each_item full">VERIFICATION ISSUES</div>
            <div className="action_headings">
              <div className="each_heading">PROBLEM DESCRIPTION</div>
              {screenResolution?.width >= 1025 && (
                <div className="each_heading_data">
                  CANDIDATE&apos;S REQUIRED ACTION
                </div>
              )}
            </div>
            {actionItems.map((eachProblem) => {
              return (
                <div className="each_action">
                  <div className="problem_status">
                    {screenResolution?.width <= 767 && (
                      <div className="small_head">PROBLEM DESCRIPTION </div>
                    )}
                    {eachProblem?.status}
                  </div>
                  <div className="problem_actions">
                    {eachProblem?.stepsMessages && (
                      <div className="each_action_section">
                        {screenResolution?.width <= 767 && (
                          <div className="small_head">
                            CANDIDATE&apos;S REQUIRED ACTION{" "}
                          </div>
                        )}
                        <ul>
                          {eachProblem?.stepsMessages.map(
                            (eachStep: any, index: any) => {
                              return (
                                <li key={index}>
                                  <span>Step {index + 1}:</span> {eachStep}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    )}
                    {eachProblem?.notesMessages && (
                      <div className="each_action_section">
                        <ul>
                          {eachProblem?.notesMessages.map(
                            (eachNote: any, index: any) => {
                              return (
                                <li key={index}>
                                  <span>Note {index + 1}:</span> {eachNote}
                                </li>
                              );
                            }
                          )}
                        </ul>
                      </div>
                    )}
                    {eachProblem?.shouldDownload && (
                      <div className="each_action_section">
                        <div className="dw_intsructions">
                          <a href={uGradAdmissions?.dlLink} target="_blank">
                            Download Instructions
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="report_msg_notes">
            <div className="msg_1">
              The Post-UTME Portal will close on Sunday, 20th October 2024.
            </div>
            <div className="msg_2">
              <div className="main_txt">Please note:</div>
              <div>No result verification, no admission, and</div>
              <div>No CAPS O'Level result upload, no admission</div>
            </div>
          </div>
        </div>
      )}
      {actionItems[0]?.shouldHide && (
        <div className="check_utme_adm_error  padded">
          <UnTabulatedReport checkMyStatus={fullData} />
        </div>
      )}
    </>
  );
};

const UnTabulatedReport = ({ checkMyStatus }: any) => {
  return (
    <div className="status_data_wrap">
      <>
        <AlertMsg
          type={checkMyStatus?.showRed ? "error" : "success"}
          message={checkMyStatus?.message || checkMyStatus?.messageOnly}
        />
        <div className="more_details_utme">
          {checkMyStatus?.registrationNumber && (
            <div className="other_info_utme init">
              <div className="info_title">UTME/DE NUMBER</div>
              <div className="info_value">
                {" "}
                {checkMyStatus?.registrationNumber}{" "}
              </div>
            </div>
          )}
          {checkMyStatus?.fullName && (
            <div className="other_info_utme ">
              <div className="info_title">CANDIDATE NAME</div>
              <div className="info_value"> {checkMyStatus?.fullName} </div>
            </div>
          )}
          {checkMyStatus?.age && (
            <div className="other_info_utme ">
              <div className="info_title">CANDIDATE AGE</div>
              <div className="info_value"> {checkMyStatus?.age} </div>
            </div>
          )}
          {checkMyStatus?.ageRequirementStatus && (
            <div className="other_info_utme ">
              <div className="info_title">CANDIDATE AGE REQUIREMENTS</div>
              <div className="info_value">
                {" "}
                {checkMyStatus?.ageRequirementStatus}{" "}
              </div>
            </div>
          )}
          {checkMyStatus?.capStatus && (
            <div className="other_info_utme ">
              <div className="info_title">CAPS O'LEVEL STATUS</div>
              <div className="info_value"> {checkMyStatus?.capStatus} </div>
            </div>
          )}
        </div>
        {checkMyStatus?.shouldDownload && (
          <div className="dw_intsructions">
            <a
              href={checkMyStatus?.dlLink || uGradAdmissions?.dlLink}
              target="_blank"
            >
              Download Instructions
            </a>
          </div>
        )}
      </>
    </div>
  );
};

const CheckCurrentStatus = ({ pageProps, setIsToPrint, isToPrint }: any) => {
  const [utmeNumber, setUtmeNumber] = useState<string>("");
  const screenResolution: any = useWindowSize();
  let checkMyStatus = pageProps?.uGradCheckMyStatusRequest;
  let checkSession = pageProps?.uGradCheckSessionRequest;

  useEffect(() => {
    getCurrentSession({ pageProps });
  }, []);
  return (
    <div>
      <div className="status_check_wrap">
        {checkSession?.request_status ===
          appConstantsUGradAdmisssions?.CHECK_CURRENT_SESSION_SUCCESS && (
          <>
            <div className="page_heading">
              Check POST (UTME/DE) & JAMB CAPS Screening Status{" "}
              {checkSession?.request_data?.response?.session}{" "}
            </div>
            <div className="other_info_wrap">
              <div className="provide_details without_border">
                <div className="details_heading">
                  ENTER YOUR JAMB REGISTRATION NUMBER TO CHECK YOUR POST-UTME
                  SCREENING & JAMB CAPS STATUS
                </div>
                <div>
                  <input
                    value={utmeNumber}
                    id="utmeNumber"
                    name="utmeNumber"
                    onChange={(e: any) => {
                      // if (e.target.value.trim().length >= 1) {
                      setUtmeNumber(e.target.value.trim());
                      // }
                    }}
                    onKeyDown={(e: any) => {
                      let keyCode = e.code || e.key;
                      if (keyCode == "Enter") {
                        getAdmissionStatus({
                          pageProps,
                          payload: {
                            registrationNumber: utmeNumber,
                          },
                        });
                      }
                    }}
                    type="text"
                    placeholder="Enter your JAMB (UTME/DE) Registration No"
                  />
                </div>

                <button
                  onClick={() => {
                    getAdmissionStatus({
                      pageProps,
                      payload: {
                        registrationNumber: utmeNumber,
                      },
                    });
                  }}
                  disabled={
                    utmeNumber.length < 4 ||
                    checkMyStatus?.is_request_processing
                  }
                  className="btn"
                >
                  {checkMyStatus?.is_request_processing
                    ? "Checking..."
                    : " Check Post-Utme Status"}{" "}
                </button>

                <div className="check_utme_adm_error">
                  {checkMyStatus.request_status ===
                    appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_FAILURE && (
                    <AlertMsg
                      type="error"
                      message={checkMyStatus?.request_data.error}
                    />
                  )}

                  {checkMyStatus.request_status ===
                    appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS && (
                    <>
                      {/* {!checkMyStatus?.request_data?.response?.problems && checkMyStatus?.request_data?.response?.message && */}
                      {checkMyStatus?.request_data?.response?.message && (
                        <UnTabulatedReport
                          checkMyStatus={checkMyStatus?.request_data?.response}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {checkSession?.request_status ===
          appConstantsUGradAdmisssions?.CHECK_CURRENT_SESSION_PENDING && (
          <LoadingItem />
        )}
        {checkSession?.request_status ===
          appConstantsUGradAdmisssions?.CHECK_CURRENT_SESSION_FAILURE && (
          <AlertMsg type="error" message={checkSession?.request_data.error} />
        )}

        {checkMyStatus.request_status ===
          appConstantsUGradAdmisssions?.CHECK_ADMISSION_PROCESS_STATUS_SUCCESS && (
          <>
            {checkMyStatus?.request_data?.response?.problems &&
              checkMyStatus?.request_data?.response?.problems[0] !== "" &&
              checkMyStatus?.request_data?.response?.problems.length >= 1 && (
                <ResultReport
                  statusData={checkMyStatus?.request_data?.response}
                />
              )}
            {screenResolution?.width >= 1025 && (
              <div className="print_cta_Wrap">
                <button
                  className="btn print_actions"
                  onClick={() => {
                    setIsToPrint(true);
                  }}
                >
                  Print
                </button>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const CheckCurrentPostUmeStatusWrap = (pageProps: any) => {
  const [isToPrint, setIsToPrint] = useState<boolean>(false);
  const componentRef = useRef<any>();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "RESULT VERIFICATION STATUS REPORT",
    onAfterPrint: () => setIsToPrint(false),
  });

  useEffect(() => {
    if (isToPrint) {
      handlePrint();
    }
  }, [isToPrint]);

  return (
    <div
      ref={componentRef}
      style={isToPrint ? { width: "100%", height: window.innerHeight } : {}}
    >
      <UndergraduateAdmissionStatusChecker
        childComponent={
          <CheckCurrentStatus
            isToPrint={isToPrint}
            setIsToPrint={setIsToPrint}
            pageProps={pageProps}
          />
        }
      />
    </div>
  );
};

const mapDispatchToProps = {
  getMyAdmissionStatus: uGradAdmissions.CheckMyAdmissionStatus,
  getCurrentSession: uGradAdmissions.CheckCurrentSession,
};
const mapStateToProps = (state: any) => ({
  uGradCheckMyStatusRequest: state.allDdmissionsReducers.uGradCheckMyStatus,
  uGradCheckSessionRequest: state.allDdmissionsReducers.uGradCheckSession,
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckCurrentPostUmeStatusWrap);
