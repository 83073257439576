

let BASE_URL = window.location.hostname!=="localhost" && window.location.hostname!=="staging.ibbuuniversity.com.ng" ? `https://api.ibbuuniversity.com.ng`: `https://staging-api.ibbuuniversity.com.ng`;
// let BASE_URL = `https://api.ibbuuniversity.com.ng`;
let UG_ADMISIONS_BASE_URL =  `${BASE_URL}/api/student/v1/UndergraduateAdmissionProcess`;
let UG_ONBOARDING_BASE_URL = `${BASE_URL}/api/student/v1/Accounts`;
let UG_PW_RESET_BASE_URL = `${BASE_URL}/api/v1/PasswordReset`;
let STUDENT_BASE_URL =  `${BASE_URL}/api/student/v1`;
let BASE_URL_V2 =  `${BASE_URL}/api/v2`;

let apiKey = window.location.hostname!=="localhost" && window.location.hostname!=="staging.ibbuuniversity.com.ng"? `b19a6d6e879b4f94b36d7b7d4b137f8d`: `b3b4a4ef9c6c4f91b0e2c9b5c6713a95`
// let apiKey = `b19a6d6e879b4f94b36d7b7d4b137f8d`
let agricId = window.location.hostname!=="localhost" && window.location.hostname!=="staging.ibbuuniversity.com.ng"? `e3d3f7b0f22d403ca4643c8e5e8b4084`: `87260f53f0b544e68055b7cbb2b45b88`
// let agricId = `e3d3f7b0f22d403ca4643c8e5e8b4084`
let sedId = window.location.hostname!=="localhost" && window.location.hostname!=="staging.ibbuuniversity.com.ng"? `32c10ec35e3b4a3ca748c700d19b50fb`: `8f593daa3ae24271b208974abfb9aa49`
// let sedId = `32c10ec35e3b4a3ca748c700d19b50fb`


export const routes = {
    apiKey,
    agricId,
    sedId,
    UNDERGRAD_CHECK_POSTUME_STATUS: `${UG_ADMISIONS_BASE_URL}/CheckPostUtmeStatus`,
    UNDERGRAD_CHECK_ADMISSION_STATUS: `${UG_ADMISIONS_BASE_URL}/CheckUndergraduateAdmission`,
    UNDERGRAD_PRINT_ACCEPTANCE: `${UG_ADMISIONS_BASE_URL}/GetOrGenerateUndergraduateAcceptanceFeeInvoice`,
    UNDERGRAD_PRINT_SCHOOL_FEE_INVOICE: `${UG_ADMISIONS_BASE_URL}/GetOrGenerateUndergraduateSchoolFeeInvoice`,
    UNDERGRAD_PRINT_ADMISSION_LETTER: `${UG_ADMISIONS_BASE_URL}/GetOrGenerateUndergraduateAdmissionLetter`,
   
    UNDERGRAD_LOGIN:  `${UG_ONBOARDING_BASE_URL}/Login`,
    UNDERGRAD_LOGOUT:  `${UG_ONBOARDING_BASE_URL}/Logout`,
    REFRESH_TOKEN: `${UG_ONBOARDING_BASE_URL}/RefreshToken`,
    UNDERGRAD_FORGOT_PW:  `${UG_PW_RESET_BASE_URL}/ResetStudentPassword`,
    UNDERGRAD_VERIFY_PW_TOKEN:  `${UG_PW_RESET_BASE_URL}/VerifyPasswordReset`,
    UNDERGRAD_RESET_PW:  `${UG_PW_RESET_BASE_URL}/ResetPassword`,
    UNDERGRAD_GET_PROFILE:  `${STUDENT_BASE_URL}/Profile/GetProfile`,
    UNDERGRAD_GET_EXAMCARD:  `${STUDENT_BASE_URL}/ExamCard/Generate`,
    UNDERGRAD_GET_FACULTY_FEE:  `${STUDENT_BASE_URL}/FacultyFee/GetOrGenerateUndergraduateFacultyFee`,
    UNDERGRAD_GET_ENTREPRENEUR_FEE:  `${STUDENT_BASE_URL}/EntrepreneurshipFee/GetOrGenerateUndergraduateEntrepreneurshipFee`,
    UNDERGRAD_GET_GST_FEE:  `${STUDENT_BASE_URL}/GstFee/GetOrGenerateUndergraduateGstFee`,
    UNDERGRAD_GET_NANISS_FEE:  `${STUDENT_BASE_URL}/NanissFee/GetOrGenerateUndergraduateNanissFee`,
    UNDERGRAD_GET_ACCEPTANCE_FEE:  `${STUDENT_BASE_URL}/AcceptanceFee/GetAcceptanceFeePaymentReceipt`,
    UNDERGRAD_GET_SCHOOL_FEE:  `${STUDENT_BASE_URL}/SchoolFee/GetOrGenerateUndergraduateSchoolFee`,
    UNDERGRAD_GET_SUG_FEE:  `${STUDENT_BASE_URL}/SugFee/GetOrGenerateUndergraduateSugFee`,
    
    GET_CURRENT_SESSION:  `${STUDENT_BASE_URL}/UndergraduateSchoolSession/GetCurrentSchoolSession`,
    GET_CURRENT_POSTUTME_SESSION:  `${STUDENT_BASE_URL}/UndergraduateSchoolSession/GetCurrentPostUtmeSession`,
    UNDERGRAD_LOAD_PRESET_COURSES:  `${STUDENT_BASE_URL}/Courses/LoadPresetStudentCourse`,
    UNDERGRAD_LOAD_MAX_COURSES:  `${STUDENT_BASE_URL}/Courses/CoursesMaxCumulativeUnit`,
    UNDERGRAD_LOAD_MY_REG_COURSES:  `${STUDENT_BASE_URL}/Courses/RegisteredCoursesForSession`,
    GET_ALL_COURSE_SUBJECTS:  `${STUDENT_BASE_URL}/UndergraduateCourses/GetAllCoursesWithNoPagination`,
    UGRAD_SAVE_COURSE_REG:  `${STUDENT_BASE_URL}/Courses/AddOrUpdateCoursesForStudent`,
    DELETE_PRESET_COURSES:  `${STUDENT_BASE_URL}/Courses/DeletePreSetCoursesForStudents`,
    PRINT_COURSE_REG:  `${STUDENT_BASE_URL}/Courses/PrintCourseRegistration`,
    
    GET_ALL_PROGRAMMES:  `${STUDENT_BASE_URL}/UndergraduateProgrammes/GetAllProgrammesWithNoPagination`,
    GET_STUDENT_BIODATA:  `${STUDENT_BASE_URL}/BioData/GetStudentBioData`,
    UPDATE_STUDENT_BIODATA:  `${STUDENT_BASE_URL}/BioData/UpdateStudentBioData`,
    PRINT_STUDENT_BIODATA:  `${STUDENT_BASE_URL}/BioData/PrintBioData`,
    UPLOAD_STUDENT_PASSPORT:  `${BASE_URL}/api/upload/v1/UndergraduateFiles/UploadUndergraduatePassport`,
    
    CHANGE_STUDENT_PASSWORD:  `${UG_ONBOARDING_BASE_URL}/ChangePassword`,

    GET_ALL_AWARDS:  `${STUDENT_BASE_URL}/Settings/GetAllAwardInView`,
    GET_ALL_HIGHEST_QUALIFICATION:  `${STUDENT_BASE_URL}/Settings/GetAllHighestQualification`,

    GET_EDOCUMENTATION_FEE: `${STUDENT_BASE_URL}/EDocumentationFee/GetOrGenerateUndergraduateEDocumentationFee`,
    GET_DOCUMENTAT_TYPES: `${STUDENT_BASE_URL}/EDocumentation/DocumentTypes`,
    UPLOAD_EDOCUMENTATIONDOCS_FRONT :  `${BASE_URL}/api/upload/v1/UndergraduateFiles/UploadEDocumentationDocsFront`,
    UPLOAD_EDOCUMENTATIONDOCS_BACK :  `${BASE_URL}/api/upload/v1/UndergraduateFiles/UploadEDocumentationDocsBack`,
    CLEARANCE_REQUESTS: `${STUDENT_BASE_URL}/EDocumentation/ClearanceRequests`,
    DOCUMENTS_HISTORY: `${STUDENT_BASE_URL}/EDocumentation/DocumentHistory`,
    COMPLETE_EDOCUMENTATION: `${STUDENT_BASE_URL}/EDocumentation/CompleteEDocumentation`,
    CHECK_ADDITIONAL_FEE: `${STUDENT_BASE_URL}/Courses/ShouldPayAdditionalFee`,
    AVAILABLE_ACCOMODATION: `${STUDENT_BASE_URL}/HostelAccommodation/AvailableAccommodation`,
    CHECK_ELIGIBILITY: `${STUDENT_BASE_URL}/HostelAccommodation/CheckEligibility`,
    BOOK_ACCOMODATION: `${STUDENT_BASE_URL}/HostelAccommodation/BookAccommodation`,
    GENERATE_HOSTEL_FEE: `${STUDENT_BASE_URL}/HostelAccomodationFee/GetOrGenerateUndergraduateHostelAccommodationFee`,
    ACCOMODATION_BOOKING: `${STUDENT_BASE_URL}/HostelAccommodation/StudentHostelAccommodationBooking`,
    ACCOMODATION_CONFIRMATION: `${STUDENT_BASE_URL}/HostelAccommodation/AccommodationConfirmation`,
    COURSE_REGISTRATION_SESSION_HISTORY: `${STUDENT_BASE_URL}/Courses/StudentCourseRegistrationSessionsHistory`,
    PRINT_COURSE_REGISTRATION_HISTORY: `${STUDENT_BASE_URL}/Courses/PrintOldCourseRegistration`,
    PRINT_PREVIOUS_RECEIPT: `${STUDENT_BASE_URL}/Receipts/AllPrevious`,
    VERIFY_QR_CODE: `${BASE_URL_V2}/VerifyV2/Check`,
    VERIFY_EXAM_CARD_QR_CODE: `${BASE_URL_V2}/VerifyV2/CheckV2`,
    
}

