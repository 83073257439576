import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import {
  getExamCardConfirmationData,
  getPdfBlob,
} from "../../../redux/action/verify-qr_code";
import { LoadingItem } from "../../../shared/components/loading";
import FrontPage from "../../../modules/exam-card/FrontPage";
import "./index.scss";

const QrVerify = () => {
  const [loading, setLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [examCardDetails, setExamCardDetails] = useState<any>({});
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get("code") || "";
  const session = queryParams.get("session") || "";
  const type = queryParams.get("type") || "";
  const semester = queryParams.get("semester") || "";

  useEffect(() => {
    const fetchPdf = async () => {
      setErrorMsg(null);
      const encodedSession = encodeURIComponent(session);
      const encodedSemester = encodeURIComponent(semester);
      if (
        (type === "EXAMCARD" && (!code || !session || !type || !semester)) ||
        (type !== "EXAMCARD" && (!code || !session || !type))
      ) {
        return setErrorMsg(
          "Required information is missing. Please check the link and try again."
        );
      }

      setLoading(true);
      let response;

      if (type === "EXAMCARD") {
        response = await getExamCardConfirmationData(
          code,
          encodedSession,
          type,
          encodedSemester
        );
        if (response.status === 200) {
          setExamCardDetails(response?.data);
        }
      } else {
        response = await getPdfBlob(code, encodedSession, type);
        if (response.status === 200) {
          setLoading(false);
          const binaryString = atob(response.data.base64Data);
          const bytes = new Uint8Array(binaryString.length);
          for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
          }
          const blob = new Blob([bytes], { type: "application/pdf" });
          const blobUrl = URL.createObjectURL(blob);
          window.open(blobUrl, "_blank");
        }
      }
      setLoading(false);
      if (response?.response?.status === 404) {
        setErrorMsg(response?.response?.data);
      } else if (response.response?.status === 400) {
        setErrorMsg(response?.response?.data);
      }
    };

    fetchPdf();
  }, [code, type, session, semester]);

  return (
    <>
      {loading ? (
        <LoadingItem />
      ) : errorMsg ? (
        <>
          <h2 className="error-text">{errorMsg}</h2>
        </>
      ) : type === "EXAMCARD" ? (
        <FrontPage
          examCardDetails={examCardDetails}
          courseDetails={examCardDetails}
        />
      ) : null}
    </>
  );
};

export default QrVerify;
