import { useEffect } from "react";
import { UndergraduatePortal } from "../../../shared/templates/portal/undergraduate";
import { connect } from "react-redux";
import { uGradPortal } from "../../../redux/action/portal/undergraduate";
import "./index.scss";
import PaymentInvoice from "./upload-document/PaymentInvoice";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";

const Edocumentation = (pageProps: any) => {
  let authInfo = pageProps.uGradLoginRequest.request_data;
  const getUnderGradProfileActionRequest = async ({
    pageProps,
    payload,
  }: any) => {
    await pageProps.getUnderGradProfile(payload);
  };

  useEffect(() => {
    let payload = {
      matricNumber: authInfo?.matricNumber,
    };

    getUnderGradProfileActionRequest({
      pageProps,
      payload,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{ marginTop: "20px" }}
      />

      <UndergraduatePortal
        title="e - Documentation"
        childComponent={<PaymentInvoice pageProps={pageProps} />}
      />
    </>
  );
};

const mapDispatchToProps = {
  getUnderGradProfile: uGradPortal.UnderGradProfile,
};
const mapStateToProps = (state: any) => ({
  UGradProfileRequest: state.allUGradPortalReducers.UGradProfile,
  uGradLoginRequest: state.allOnboardingReducers.uGradLogin,
});
export default connect(mapStateToProps, mapDispatchToProps)(Edocumentation);
