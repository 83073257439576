import React from "react";
import { useSelector } from "react-redux";

const Invoice = ({ studentInfo }: any) => {
  const state = useSelector((state: any) => state);
  const { transactionId, amount, downloadUrl, session, paymentStatus } =
    state.eDocumentation;
  const { fullName, matricNumber, level } = studentInfo;
  return (
    <div className="invoice-container">
      <h3>Payment Invoice</h3>
      <div className="payment-invoice">
        <div className="trans-row">
          <strong className="red">Transaction ID:</strong>
          <strong className="red">{transactionId}</strong>
        </div>
        <div className="trans-row">
          <strong>Payment item:</strong>
          <strong>E-Documentation Fee</strong>
        </div>
        <div className="trans-row">
          <strong>Payer Full Name:</strong>
          <strong>{fullName}</strong>
        </div>
        <div className="trans-row">
          <strong>Matric Number:</strong>
          <strong>{matricNumber}</strong>
        </div>
        <div className="trans-row">
          <strong>Level:</strong>
          <strong>{`${level}L`}</strong>
        </div>
        <div className="trans-row">
          <strong className="red">Amount Payable:</strong>
          <strong className="red">{amount}</strong>
        </div>

        <div className="trans-row">
          <strong>Session:</strong>
          <strong>{session}</strong>
        </div>
        <div className="trans-row">
          <strong>Payment Status:</strong>
          <strong>{paymentStatus}</strong>
        </div>
      </div>

      <div className="generate-edoc-invoice">
        <a
          target="_blank"
          className="edoc-link"
          rel="noreferrer"
          href={downloadUrl}
        >
          Download Invoice
        </a>
      </div>
    </div>
  );
};

export default Invoice;
