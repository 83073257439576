import { useCallback, useEffect, useState } from "react";

import {
  bookAccomodation,
  getAvailableAccomodations,
} from "../../../../redux/action/hostels";
import Site from "../Site";
import { LoadingItem } from "../../../../shared/components/loading";
import Terms from "../Terms";
import { history } from "../../../../shared/_helpers/history";
import { toast } from "react-toastify";
import { uGradPortal } from "../../../../redux/action/portal/undergraduate";
import { useDispatch } from "react-redux";

export interface Room {
  roomName: string;
  roomUniqueId: string;
}

export interface Block {
  blockName: string;
  blockUniqueId: string;
  rooms: Room[];
}

interface SingleSite {
  siteName: string;
  blocks: Block[];
}

export interface Room {
  roomName: string;
  roomUniqueId: string;
}

export interface Block {
  blockName: string;
  blockUniqueId: string;
  rooms: Room[];
}

interface SingleSite {
  siteName: string;
  blocks: Block[];
}

const SiteList = ({ student }: any) => {
  // State to track selected block and room per site
  const [selectedData, setSelectedData] = useState<{
    [key: string]: { block: string; room: string };
  }>({});
  const [availableHostels, setAvailableHostels] = useState<SingleSite[]>([]);
  const [loading, setLoading] = useState(false);
  const [noRooms, setNoRooms] = useState("");
  const [showTermsModal, setShowTermsModal] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [isBooking, setIsBooking] = useState(false);

  const dispatch = useDispatch();

  // Fetch available accommodations
  const getAllAvailableAccomodations = useCallback(
    async (studentId: string) => {
      setLoading(true);
      setNoRooms("");
      const res = await getAvailableAccomodations(studentId);

      if (res?.data?.length) {
        setAvailableHostels(res.data);
      } else if (res?.response?.status === 404) {
        setNoRooms(res?.response?.data?.message);
      } else if (
        res?.response?.data?.includes(
          "Hostel accommodation booking has not yet opened."
        )
      ) {
        setNoRooms("Hostel accommodation booking has not yet opened.");
      }

      setLoading(false);
    },
    []
  );

  useEffect(() => {
    getAllAvailableAccomodations(student?.studentUniqueId);
  }, [getAllAvailableAccomodations, student?.studentUniqueId]);

  // Handle Block Selection
  const handleBlockChange = (siteName: string, blockUniqueId: string) => {
    setSelectedData({
      [siteName]: { block: blockUniqueId, room: "" }, // Reset room when block changes
    });
    setInputError(false);
  };

  // Handle Room Selection
  const handleRoomSelect = (siteName: string, roomUniqueId: string) => {
    setSelectedData((prev) => ({
      ...prev,
      [siteName]: { ...prev[siteName], room: roomUniqueId },
    }));
    setInputError(false);
  };

  // Handle Proceed Button Click
  const handleProceed = (siteName: string) => {
    const selectedSite = selectedData[siteName];
    if (!selectedSite?.block || !selectedSite?.room) {
      setInputError(true);
      return;
    }
    setShowTermsModal(true);
  };

  // Submit booking request
  const handleSubmitBooking = async () => {
    setIsBooking(true);
    const selectedSite = Object.keys(selectedData)[0];
    const selectedBlock = selectedData[selectedSite]?.block;
    const selectedRoom = selectedData[selectedSite]?.room;

    const bookingPayload = {
      studentId: student?.studentUniqueId,
      matricNumber: student?.matricNumber,
      hostelSite: selectedSite,
      blockUniqueId: selectedBlock,
      roomUniqueId: selectedRoom,
    };

    const res = await bookAccomodation(bookingPayload);
    await dispatch(
      uGradPortal.UnderGradProfile({ matricNumber: student?.matricNumber })
    );
    setIsBooking(false);
    setShowTermsModal(false);

    if (res?.status === 200) {
      history.push("/undergraduate/hostel-fee");
    } else if (res?.response?.status < 400) {
      toast.error(res?.response?.data);
    }
  };

  return (
    <div className="site-list">
      <Terms
        show={showTermsModal}
        setShow={setShowTermsModal}
        handleSubmitBooking={handleSubmitBooking}
        loading={isBooking}
      />
      {loading ? (
        <LoadingItem />
      ) : availableHostels?.length ? (
        availableHostels.map((site) => (
          <Site
            key={site.siteName}
            site={site}
            selectedBlock={selectedData[site.siteName]?.block || ""}
            selectedRoom={selectedData[site.siteName]?.room || ""}
            handleBlockChange={handleBlockChange}
            onRoomSelect={handleRoomSelect}
            handleProceed={handleProceed}
            inputError={inputError}
          />
        ))
      ) : (
        <h3>{noRooms}</h3>
      )}
    </div>
  );
};
export default SiteList;
