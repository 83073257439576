import { connect } from "react-redux";
import { useEffect, useState } from "react";
import { Formik, Form, Field, FormikProps, ErrorMessage } from "formik";
import * as Yup from "yup";
import { AlertMsg } from "../../../../shared/components/alert-msg";
import Logo from "../../../../assets/logo2.png";
import PwSeen from "../../../../assets/pw-visible.png";
import PwHide from "../../../../assets/pw-hidden.png";
import Back from "../../../../assets/Go-Back.png";
import { Link } from "react-router-dom";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import { uGradOnboarding } from "../../../../redux/action/onboarding/undergrad";
import { appConstantsUGradOnboarding } from "../../../../redux/action-constants/onboarding";
import "./index.scss";
import "../index.scss";

const uGradLoginActionRequest = async ({ pageProps, payload }: any) => {
  await pageProps.uGradLoginAction(payload);
};

const UGLogin = (pageProps: any) => {
  const [isPwShown, setShowPw] = useState<boolean>(false);
  const screenResolution: any = useWindowSize();
  const reloadVariable = localStorage.getItem("adRld");

  let yearTxt = new Date().getFullYear();

  useEffect(() => {
    if (
      !reloadVariable &&
      requestInfo.request_status == appConstantsUGradOnboarding?.LOGIN_USER_RESET
      // requestInfo.request_status !== appConstantsUGradOnboarding?.LOGIN_USER_FAILURE
    ) {
      localStorage.setItem("adRld", JSON.stringify(true));
       window.location.reload();
    }
  }, [reloadVariable]);

  let requestInfo = pageProps?.uGradLoginRequest;

  useEffect(() => {
    uGradLoginActionRequest({
      pageProps,
      payload: "CLEAR",
    });
    pageProps.logoutAction();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  let checkValidationSchema = Yup.object().shape({
    matricNumber: Yup.string().required("Required"),
    password: Yup.string().required("Required"),
  });
  let initialValues: any = { matricNumber: "", password: "" };
  return (
    <>
      <div className="onboarding_wrap">
        <div className="login-form-wrapper">
          <div className="onboarding_content splitted">
            {screenResolution?.width >= 767 && (
              <div className="each_side summary ipad-view">
                <div>
                  <div className="logo">
                    <a href="https://ibbu.edu.ng/">
                      <img src={Logo} alt="" />
                    </a>
                  </div>
                  <div className="school_name">
                    IBRAHIM BADAMASI BABANGIDA UNIVERSITY
                  </div>
                  <div className="student_type_txt">
                    FULL-TIME STUDENT <br /> SIGN IN
                  </div>
                </div>
              </div>
            )}
            {screenResolution?.width <= 766 && (
              <div className="mobile_heading">
                <div className="logo">
                  <a href="https://ibbu.edu.ng/">
                    <img src={Logo} alt="" />
                  </a>
                </div>
                <div className="school_name">
                  IBRAHIM BADAMASI BABANGIDA UNIVERSITY
                </div>
                {/* <div className="student_type_txt">FULL-TIME  STUDENT <br /> SIGN IN</div> */}
              </div>
            )}
            <div className="each_side form_info">
            <div className="back-to-main">
              <a
                // className="back-to-website"
                href="https://ibbu.edu.ng/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={Back} alt="Go back" /> &nbsp;BACK TO UNIVERSITY
                WEBSITE
              </a>
            </div>
              <div className="form_heading">UNDERGRADUATE PORTAL</div>
              <div className="form_heading">WELCOME, PLEASE LOGIN</div>
              <Formik
                initialValues={initialValues}
                validationSchema={checkValidationSchema}
                onSubmit={async (values) => {
                  let { matricNumber, password } = values;
                  let payload: any = {
                    matricNumber,
                    password,
                  };
                  await uGradLoginActionRequest({ pageProps, payload });
                }}
              >
                {(props: FormikProps<any>) => {
                  const {
                    values,
                    touched,
                    errors,
                    // handleBlur,
                    handleChange,
                    // isSubmitting,
                  } = props;
                  return (
                    <Form className="form-wrapper">
                      <div className="form-group">
                        <label className="label">Matric Number </label>
                        <div
                          className={
                            errors.matricNumber && touched.matricNumber
                              ? "invalid-input-wrap"
                              : ""
                          }
                        >
                          <Field
                            value={values.matricNumber}
                            id="matricNumber"
                            name="matricNumber"
                            type="text"
                            onChange={handleChange("matricNumber")}
                            placeholder="Enter Matric Number"
                            className={`input-wrap ${
                              errors.matricNumber && touched.matricNumber
                                ? "is-invalid-input "
                                : !errors.matricNumber &&
                                  values.matricNumber !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                        </div>
                        <ErrorMessage
                          name="matricNumber"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      <div className="form-group">
                        <label className="label">Password </label>
                        <div
                          className={
                            errors.password && touched.password
                              ? "invalid-input-wrap password_wrap"
                              : " password_wrap"
                          }
                        >
                          <Field
                            value={values.password}
                            id="password"
                            name="password"
                            type={!isPwShown ? "password" : "text"}
                            onChange={handleChange("password")}
                            placeholder="Enter Password"
                            className={`input-wrap ${
                              errors.password && touched.password
                                ? "is-invalid-input "
                                : !errors.password && values.password !== ""
                                ? "valid-input"
                                : ""
                            }`}
                          />
                          <div
                            className="show-hide"
                            onClick={() => setShowPw(!isPwShown)}
                          >
                            {isPwShown && <img src={PwHide} alt="" />}
                            {!isPwShown && <img src={PwSeen} alt="" />}
                          </div>
                        </div>
                        <ErrorMessage
                          name="password"
                          className="form-input-error"
                          component="div"
                        />
                      </div>
                      {requestInfo.request_status ===
                        appConstantsUGradOnboarding?.LOGIN_USER_FAILURE && (
                        <AlertMsg
                          type="error"
                          message={requestInfo?.request_data.error}
                        />
                      )}
                      <div className="submit_cta">
                        <button
                          disabled={requestInfo?.is_request_processing}
                          className="btn shaded"
                          type="submit"
                        >
                          {requestInfo?.is_request_processing
                            ? "Please wait..."
                            : "Login"}
                        </button>
                      </div>
                      <div className="forgot_cta">
                        Forgot your password ?{" "}
                        <Link to="/undergraduate/accounts/forgot-password">
                          Get it Here
                        </Link>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>

        {screenResolution?.width >= 767 && (
          <div className="login-footer">
            <p>
              {" "}
              <span>&copy; {yearTxt}</span> IBB University. All rights reserved
            </p>
            <p>
              Developed by{" "}
              <a
                href="https://gigbadi.com.ng/"
                target="_blank"
                className="footer-link"
              >
                Gigbadi
              </a>
            </p>
          </div>
        )}

        {screenResolution?.width < 767 && (
          <div className="mobile-footer">
            <p>
              {" "}
              <span>&copy; {yearTxt}</span> IBB University. All rights reserved
            </p>
            <p>
              Developed by{" "}
              <Link
                to="https://gigbadi.com.ng/"
                target="_blank"
                className="footer-link"
              >
                Gigbadi
              </Link>
            </p>
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = {
  uGradLoginAction: uGradOnboarding.UnderGradLogin,
  logoutAction: uGradOnboarding.Logout,
};
const mapStateToProps = (state: any) => ({
  uGradLoginRequest: state.allOnboardingReducers.uGradLogin,
});
export default connect(mapStateToProps, mapDispatchToProps)(UGLogin);
